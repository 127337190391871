import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import ServiceHero from "../assets/images/collect-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconMessageWhite from "../assets/icons/icon-message-white.svg";
import IconMessageDark from "../assets/icons/icon-message-dark.svg";
import IconBarWhite from "../assets/icons/icon-bar-white.svg";
import IconBarDark from "../assets/icons/icon-bar-dark.svg";
import IconTimeWhite from "../assets/icons/icon-time-white.svg";
import IconTimeDark from "../assets/icons/icon-time-dark.svg";
import CollectSafeSpace from "../assets/images/collect-safe-space.jpg";
import CollectSafeSpaceForm from "../assets/images/collect-safe-space-form.png";
import CollectSecureChannel from "../assets/images/collect-secure-channel.png";
import CollectRelevantPolicy from "../assets/images/collect-relevant-policy.png";
import CollectLodgingCapability from "../assets/images/collect-lodging-capability.jpg";
import CollectLodgingCapabilityProgress from "../assets/images/collect-lodging-capability-progress.png";
import ServiceStackedImage from "../assets/images/collect-transparent-culture.jpg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesValueData = [
  {
    id: "SV0001",
    imageWhite: IconMessageWhite,
    imageDark: IconMessageDark,
    title: "Issues aren't reported",
    description:
      "Important issues remain hidden under the surface, which is a missed opportunity to understand and address them promptly. Not having a safe outlet to share concerns internally also increases the likelihood of people sharing concerns externally.",
  },
  {
    id: "SV0002",
    imageWhite: IconBarWhite,
    imageDark: IconBarDark,
    title: "Can't report on mental health",
    description:
      "If mental health conversations and wellbeing feedback isn't shared internally, leaders can't capture this data. This makes it hard to report to key stakeholders on mental health and make the right strategic adjustments to create positive organisational change.",
  },
  {
    id: "SV0003",
    imageWhite: IconTimeWhite,
    imageDark: IconTimeDark,
    title: "No documentation",
    description:
      "Leaders can't show processes to identify issues and if people don't express concerns internally, leaders can't document processes taken to support. This is important information to document in case situations escalate to external resolution processes.",
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "Can it be integrated?",
    answer:
      "Yes, we integrate with various platforms and maintain an open api. Please speak to us about our integrations.",
  },
  {
    id: "FAQ0002",
    question: "Can we customise it?",
    answer:
      "Yes, check-ins, concerns and surveys can be fully customised to suit your needs.",
  },
  {
    id: "FAQ0003",
    question: "How often should we check in?",
    answer: "We recommend quarterly check-ins.",
  },
  {
    id: "FAQ0004",
    question: "How do other organisations use it? Best practice?",
    answer: (
      <>
        We recommend introducing Skodel in a meaningful way and running
        quarterly check-ins with a post check-in action plan each time. For more
        guidance on how to do this, you can review Skodel's{" "}
        <a
          href="https://help.skodel.com/kb/guide/en/getting-started-on-skodel-gzeUNucBWl/Steps/2611248"
          target="_blank"
        >
          getting started guide.
        </a>
      </>
    ),
  },
  {
    id: "FAQ0005",
    question: "How do we upload users?",
    answer: "CSV or via an integration.",
  },
  {
    id: "FAQ0006",
    question: "Does Skodel have an app?",
    answer: "Yes, Skodel can be used via a web or mobile app.",
  },
  {
    id: "FAQ0007",
    question:
      "What happens if someone discloses a psychological risk on Skodel?",
    answer:
      "The aim with Skodel is to embed your policies and procedures to be triggered to users if a risk is reported. This ensures sensitive issues are always safely responded to. Doing this digitally means leaders maintain an audit trail of issues reported and responses and support provided.",
  },
];

const SkodelDiscover = () => {
  const [servicesValue, setServicesValue] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string | React.ReactNode }[]
  >([]);

  useEffect(() => {
    setServicesValue(ServicesValueData);
    setFAQ(FAQData);
  }, [setServicesValue, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${ServiceHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>Skodel Discover</h1>
              <p>
                Collect mental wellbeing data inside your organisation with
                surveys, incident reporting and check-in software built for
                mental wellbeing. Email, web, mobile and advanced integrations.
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
                <a
                  href="https://skodel.notion.site/Skodel-Discovery-a90083ec33764dd7a3a6dd49f55ceb22?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-white btn-hero-cta"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="service-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>The problem</h2>
                  <p>
                    People don't have an always available confidential outlet to
                    report mental health and wellbeing concerns inside an
                    organisation
                  </p>
                </div>
                <div className="service-value-grid column-3">
                  {servicesValue?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="service-zigzag-content">
                <div className="service-zigzag-wrapper collect-safe">
                  <div className="service-zigzag-image">
                    <img src={CollectSafeSpace} alt={"Skodel"} />
                    <div className="collect-safe-space">
                      <img src={CollectSafeSpaceForm} alt={"Skodel"} />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>
                      A private space to share mental health and wellbeing
                      matters
                    </h3>
                    <p>
                      From simple one question pulse check-ins to in-depth
                      yearly assessments, Skodel helps you create mental health
                      check-ins and surveys for people to privately share their
                      mental health. Our form builder includes configurable
                      templates and a framework designed by psychologists that
                      increases the likelihood of people expressing meaningful
                      mental health data. Proactively send these check-ins out
                      and or leave them always on for people to privately check
                      in and share concerns at anytime.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={CollectSecureChannel} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Secure multi-channel delivery methods</h3>
                    <p>
                      Our surveys and check-ins can be securely delivered across
                      web, mobile, email and via advanced integrations. We work
                      with the communication channel that works best for you to
                      drive higher response rates, increase awareness of support
                      and streamline adoption. Advanced scheduling functionality
                      enables you to deliver check-ins on a one off or recurring
                      basis to individuals, groups or the whole organisation
                      anonymously or identifiably.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={CollectRelevantPolicy} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>An appropriate response for every concern</h3>
                    <p>
                      Skodel enables you to integrate resolution processes
                      inside your data collection process. This could include
                      linking people to next steps, lines of support or
                      suggesting relevant policies and procedures based on the
                      information they have shared in their check-in or concern.
                      Tying in a resolution process when collecting data means
                      support is always there. This helps organisations address
                      mental health concerns and prevent situations from
                      escalating, even when human intervention isn't possible.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper collect-lodging">
                  <div className="service-zigzag-image">
                    <img src={CollectLodgingCapability} alt={"Skodel"} />
                    <div className="collect-lodging-capability">
                      <img
                        src={CollectLodgingCapabilityProgress}
                        alt={"Skodel"}
                      />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Anonymous concern lodging capability</h3>
                    <p>
                      Turn on concern lodging so everyone in your organisation
                      can anonymously report concerns when they need to. Define
                      the categories you want to track for concerns and
                      customise the post lodgement messaging to link people to
                      relevant policies and procedures. Setup alerts for these
                      concerns to be sent to the right people. Our aim is to
                      encourage concerns people have to be reported and resolved
                      internally as it provides valuable insight for leaders
                      that may otherwise get missed if unreported and promotes a
                      positive culture by demonstrating commitment to addressing
                      issues.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={ServiceStackedImage}
                  title={"An open and transparent culture"}
                  description={
                    "When issues relating to mental health arise in an organisation, it's often not lack of commitment that prevents leaders from supporting but rather lack of visibility. Most leaders don't want to create an environment that causes or exacerbates situations such as bullying or burnout. The challenge is knowing when these situations are taking place and how best to resolve these situations. Skodel gives leaders better visibility on these matters and integrates effective resolution processes to resolve them promptly."
                  }
                />
              </div>
              <div className="service-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="service-faq-content">
                  <div className="service-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default SkodelDiscover;
